/* Generated by ParaType (http://www.paratype.com)*/
/* Font PT Root UI: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
  font-family: "PTRootUI";
  src:
    url("./pt-root-ui_regular.woff2") format("woff2"),
    url("./pt-root-ui_regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PTRootUI";
  src:
    url("./pt-root-ui_light.woff2") format("woff2"),
    url("./pt-root-ui_light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PTRootUI";
  src:
    url("./pt-root-ui_medium.woff2") format("woff2"),
    url("./pt-root-ui_medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PTRootUI";
  src: url("./pt-root-ui_semi-bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PTRootUI";
  src:
    url("./pt-root-ui_bold.woff2") format("woff2"),
    url("./pt-root-ui_bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
