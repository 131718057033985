/* Semantic tokens */
:root {
  /**
    * ===========================================*
    * ========== Background Colors ==============*
    * ===========================================*
  */

  /** Used as active bg when --ads-v2-color-bg-brand is the default bg **/
  --ads-v2-color-bg-brand-emphasis-plus: var(--ads-v2-color-orange-800);
  /** Used as hover bg when --ads-v2-color-bg-brand is the default bg **/
  --ads-v2-color-bg-brand-emphasis: var(--ads-v2-color-orange-700);
  /** Brand primary color **/
  --ads-v2-color-bg-brand: var(--ads-v2-color-orange-600);

  /** Used as active bg when --ads-v2-color-bg-brand-secondary is the default bg */
  --ads-v2-color-bg-brand-secondary-emphasis-plus: var(--ads-v2-color-gray-600);
  /** Used as hover bg when --ads-v2-color-bg-brand-secondary is the default bg */
  --ads-v2-color-bg-brand-secondary-emphasis: var(--ads-v2-color-gray-700);
  /** Brand secondary color */
  --ads-v2-color-bg-brand-secondary: var(--ads-v2-color-gray-600);

  /** Used for tooltips or any high contrast components */
  --ads-v2-color-bg-emphasis-max: var(--ads-v2-color-gray-800);
  /** Used for hover state for components without border */
  --ads-v2-color-bg-emphasis-plus: var(--ads-v2-color-gray-400);
  /** Used for components without border */
  --ads-v2-color-bg-emphasis: var(--ads-v2-color-gray-300);
  /** Default BG */
  --ads-v2-color-bg: var(--ads-v2-color-white);
  /* Used for emphasis default bg */
  --ads-v2-color-bg-muted: var(--ads-v2-color-gray-200);
  /** Used for disabled components */
  --ads-v2-color-bg-subtle: var(--ads-v2-color-gray-100);

  /** Used for error background active */
  --ads-v2-color-bg-error-emphasis-plus: var(--ads-v2-color-red-300);
  /** Used for error background hover  */
  --ads-v2-color-bg-error-emphasis: var(--ads-v2-color-red-200);

  /** Used for error background */
  --ads-v2-color-bg-error: var(--ads-v2-color-red-100);
  /** Used for success background */
  --ads-v2-color-bg-success: var(--ads-v2-color-green-100);
  /** Used for warning background */
  --ads-v2-color-bg-warning: var(--ads-v2-color-yellow-100);
  /** Used for information background */
  --ads-v2-color-bg-information: var(--ads-v2-color-blue-100);
  /** Used for special background */
  --ads-v2-color-bg-special: var(--ads-v2-color-purple-100);
  /** Used for premium background */
  --ads-v2-color-bg-premium: var(--ads-v2-color-orange-50);

  /**
    * ===========================================*
    * ========== Foreground Colors ==============*
    * ===========================================*
  */

  /** Used for content with high importance like main heading */
  --ads-v2-color-fg-emphasis-plus: var(--ads-v2-color-gray-800);
  /** Used for content with lesser importance than title. Like subtitle */
  --ads-v2-color-fg-emphasis: var(--ads-v2-color-gray-700);
  /** Default foreground color */
  --ads-v2-color-fg: var(--ads-v2-color-gray-600);
  /** Used for content with lesser importance than body content */
  --ads-v2-color-fg-muted: var(--ads-v2-color-gray-500);
  /** Used for all least important texts like placeholders, disabled text */
  --ads-v2-color-fg-subtle: var(--ads-v2-color-gray-400);
  /** Text color with special meaning */
  --ads-v2-color-fg-brand: var(--ads-v2-color-orange-600);
  /** Hover for text color with special meaning */
  --ads-v2-color-fg-brand-emphasis: var(--ads-v2-color-orange-700);
  /** Active for text color with special meaning */
  --ads-v2-color-fg-brand-emphasis-plus: var(--ads-v2-color-orange-800);

  /** Text color for background --ads-v2-color-bg-brand */
  --ads-v2-color-fg-on-brand: var(--ads-v2-color-white);
  /** Text color for background --ads-v2-color-bg-brand-secondary */
  --ads-v2-color-fg-on-brand-secondary: var(--ads-v2-color-white);
  /** Text color for background --ads-v2-color-bg-emphasis-plus */
  --ads-v2-color-fg-on-emphasis-plus: var(--ads-v2-color-white);
  /** Text color for background --ads-v2-color-bg-emphasis-max */
  --ads-v2-color-fg-on-emphasis-max: var(--ads-v2-color-white);
  /** Text color for background --ads-v2-color-bg-error */
  --ads-v2-color-fg-on-error: var(--ads-v2-color-red-600);
  /** Text color for background --ads-v2-color-bg-success */
  --ads-v2-color-fg-on-success: var(--ads-v2-color-white);
  /** Text color for background --ads-v2-color-bg-warning */
  --ads-v2-color-fg-on-warning: var(--ads-v2-color-white);

  /** Foreground color for error */
  --ads-v2-color-fg-error: var(--ads-v2-color-red-600);
  /** Foreground color for success */
  --ads-v2-color-fg-success: var(--ads-v2-color-green-600);
  /** Foreground color for warning */
  --ads-v2-color-fg-warning: var(--ads-v2-color-yellow-600);
  /** Foreground color for information*/
  --ads-v2-color-fg-information: var(--ads-v2-color-blue-600);
  /** Foreground color for special */
  --ads-v2-color-fg-special: var(--ads-v2-color-purple-700);
  /** Foreground color for premium */
  --ads-v2-color-fg-premium: var(--ads-v2-color-orange-600);

  /**
    * ===========================================*
    * ============ Border Colors ================*
    * ===========================================*
  */

  /** Used for indicating active borders */
  --ads-v2-color-border-emphasis-plus: var(--ads-v2-color-gray-600);
  /** Used for indicating hover borders */
  --ads-v2-color-border-emphasis: var(--ads-v2-color-gray-400);
  /** Default border color */
  --ads-v2-color-border: var(--ads-v2-color-gray-300);
  /** Used for indicating disabled borders and dividers */
  --ads-v2-color-border-muted: var(--ads-v2-color-gray-200);

  /** Used as active border when --ads-v2-color-bg-brand is the default bg */
  --ads-v2-color-border-brand-emphasis-plus: var(--ads-v2-color-orange-800);
  /** Used as hover border when --ads-v2-color-bg-brand is the default bg */
  --ads-v2-color-border-brand-emphasis: var(--ads-v2-color-orange-700);
  /** Used as active border hover color of secondary items like radio checkbox etc */
  --ads-v2-color-border-brand-secondary-emphasis: var(--ads-v2-color-gray-700);
  /** Used as active border color of secondary items like radio checkbox etc */
  --ads-v2-color-border-brand-secondary: var(--ads-v2-color-gray-600);
  /** Used as default border color when --ads-v2-color-bg-brand is the default bg */
  --ads-v2-color-border-brand: var(--ads-v2-color-orange-600);
  /** Used for indicating error borders */
  --ads-v2-color-border-error: var(--ads-v2-color-red-400);
  /** Used for indicating success borders */
  --ads-v2-color-border-success: var(--ads-v2-color-green-600);
  /** Used for indicating warning borders */
  --ads-v2-color-border-warning: var(--ads-v2-color-yellow-600);
  /** Used for indicating information borders */
  --ads-v2-color-border-info: var(--ads-v2-color-blue-600);
  /** Used for indicating special borders */
  --ads-v2-color-border-special: var(--ads-v2-color-purple-300);
  /** Used for indicating premium borders */
  --ads-v2-color-border-premium: var(--ads-v2-color-orange-300);
}
