/* ubuntu-300 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  src: url("./ubuntu-v20/ubuntu-v20-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-300.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* ubuntu-300italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 300;
  src: url("./ubuntu-v20/ubuntu-v20-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-300italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* ubuntu-regular - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("./ubuntu-v20/ubuntu-v20-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-regular.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* ubuntu-italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 400;
  src: url("./ubuntu-v20/ubuntu-v20-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* ubuntu-500 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url("./ubuntu-v20/ubuntu-v20-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-500.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* ubuntu-500italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 500;
  src: url("./ubuntu-v20/ubuntu-v20-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-500italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* ubuntu-700 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: url("./ubuntu-v20/ubuntu-v20-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-700.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* ubuntu-700italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 700;
  src: url("./ubuntu-v20/ubuntu-v20-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-700italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
